import { type InitType } from "@intercom/messenger-js-sdk/dist/types";
import IntercomInit from "@intercom/messenger-js-sdk";
import { observer } from "mobx-react-lite";
import React, { useContext, type FunctionComponent } from "react";
import AppContext from "~/contexts/AppContext";

const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;

const Intercom: FunctionComponent = observer(function Intercom() {
  const appStore = useContext(AppContext);

  if (!appStore.loaded) return null;

  let intercom: InitType = { app_id: intercomAppId };
  if (appStore.authenticated) {
    intercom = {
      ...intercom,
      user_id: appStore.currentUser?.uuid,
      user_type: appStore.currentUser?.userType,
      email: appStore.currentUser?.email,
      name: appStore.currentUser?.fullName,
      user_hash: appStore.currentUser?.intercomUserHash,
    };
  }

  IntercomInit(intercom);

  return <></>;
});

export default Intercom;
