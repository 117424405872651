import { action, makeAutoObservable } from "mobx";
import { type User, getCurrentUser } from "~/api/CurrentUser";

export default class AppStore {
  authenticated: boolean;
  loaded: boolean = false;
  currentUser: User | null = null;
  trueUser: User | null = null;
  impersonated: boolean | null = null;

  gTranslateConfiguration?: any;
  gTranslateEnabled: boolean = false;

  constructor(authenticated: boolean, gTranslateConfiguration?: any) {
    this.authenticated = authenticated;
    this.gTranslateConfiguration = gTranslateConfiguration;
    makeAutoObservable(this);
  }

  load(): void {
    if (!this.authenticated) {
      this.loaded = true;
      return;
    }

    getCurrentUser()
      .then(
        action("fetchCurrentUser", (currentUser) => {
          this.currentUser = currentUser.currentUser;
          this.trueUser = currentUser.trueUser;
          this.impersonated = currentUser.impersonated;

          this.loaded = true;
        })
      )
      .catch(() => {});
  }

  enableGTranslate(): void {
    if (this.gTranslateConfiguration == null) return;

    (window as any).gtranslateSettings = this.gTranslateConfiguration;

    const element = document.querySelector(
      "script[src='https://cdn.gtranslate.net/widgets/latest/float.js']"
    );
    if (element != null) return;

    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    script.async = true;
    document.head.appendChild(script);
  }
}
