import AuthenticatedAxios from "~/services/AuthenticatedAxios";

export interface User {
  uuid: string;
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  userType: string;
  userRole: string;
  homePage: string;
  intercomUserHash: string;
}

export interface GetCurrentUserResponse {
  currentUser: User;
  trueUser: User;
  impersonated: boolean;
}

export const getCurrentUser = async (): Promise<GetCurrentUserResponse> => {
  const response = await AuthenticatedAxios.get<GetCurrentUserResponse>(
    "/api/current_user"
  );
  return response.data;
};
